import { Button, Flex, Box, VStack, Spinner } from "@chakra-ui/react";
import { useContext, useState, useEffect } from "react";
import AccountContext from "@/context/account";
import { RouteComponentProps } from "react-router-dom";
import useTotalSupply from "@/libs/useTotalSupply";
import useTokenId from "@/libs/useTokenId";
import useApiCall from "@/libs/useApiCall";

const RainbowKitPlayground = ({
  history,
}: {
  history: RouteComponentProps["history"];
}) => {
  const { account } = useContext(AccountContext);
  const [auth, setAuth] = useState<string | null>(null);
  const { totalSupply: totalMint } = useTotalSupply();
  const { tokenId } = useTokenId(account || "");
  const { isLoading } = useApiCall();

  useEffect(() => {
    const storedAuth = localStorage.getItem("auth");
    setAuth(storedAuth);
  }, []);

  return (
    <Flex
      height="calc(100vh - 94px)"
      bg="black"
      mt="94px"
      px={3}
      direction={["column", "row"]}
      align="center"
      justify="center"
    >
      <VStack spacing={6} width="100%" maxW="md">
        <Box>
          <Button
            display="flex"
            bg="#21221D"
            py={6}
            border="1px solid #52534F"
            color="white"
            width={300}
            textTransform="none"
            // onClick={}
          >
            {isLoading ? <Spinner /> : "Get User"}
          </Button>
        </Box>

        {auth && (
          <Box
            px={4}
            py={2}
            bg="gray.800"
            color="white"
            borderRadius="md"
            maxW="100%"
          >
            accessToken: {auth}
          </Box>
        )}

        {auth && (
          <Box
            px={4}
            py={2}
            bg="gray.800"
            color="white"
            borderRadius="md"
            maxW="100%"
          >
            tokenId: {tokenId}
          </Box>
        )}

        <Box
          px={4}
          py={2}
          bg="gray.800"
          color="white"
          borderRadius="md"
          maxW="100%"
        >
          totalSupply: {totalMint}
        </Box>
      </VStack>
    </Flex>
  );
};

export default RainbowKitPlayground;
