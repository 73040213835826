import { useState, useCallback } from 'react';

const useApiCall = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleApiCall = useCallback(async (apiCall: () => Promise<any>) => {
    setIsLoading(true);
    try {
      await apiCall();
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, handleApiCall };
};

export default useApiCall;
